<template>
  <v-container fluid>
    <v-card v-if="isLoaded">
      <v-toolbar color="indigo lighten-5" flat>
        <BackButton />
        <v-toolbar-title>
          {{ additionalInfoArticle.name.uk }}
          <span v-if="additionalInfoArticle.category">категорії "{{ additionalInfoCategory.name.uk }}"</span>
          <span v-else>без категорії</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="font-italic font-weight-medium">Картка статті</div>
      </v-toolbar>

      <v-card flat color="blue-grey lighten-5">
        <Article
          :additionalInfoArticle="additionalInfoArticle"
          :additionalInfoSelectedEventType="additionalInfoSelectedEventType"
        ></Article>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import { ROUTER_LINKS } from '@/const/router-links.enum'
import BackButton from '@/components/common/BackButton.vue'

export default {
  name: 'InfoArticleCard',

  components: {
    BackButton,
    Article: () => import('@/components/support/info_constructor/categories_articles/LocaleCard'),
  },

  data() {
    return {
      ROUTER_LINKS: ROUTER_LINKS,
      isLoaded: false,
    }
  },

  computed: {
    ...mapState('infoConstructor', ['additionalInfoCategory', 'additionalInfoArticle', 'additionalInfoSelectedEventType']),
    ...mapGetters('infoConstructor', ['getCreatedAdditionalInfoArticleId']),

    currentCompanyId() {
      return this.$route.params.id
    },
    categoryId() {
      return this.additionalInfoArticle.category?.id
    },
    articleId() {
      return this.$route.query.articleId
    },
  },

  created() {
    this.initialize()
  },

  beforeDestroy() {
    this.SET_SELECTED_ADDITIONAL_INFO_ARTICLE({})
    this.SET_SELECTED_ADDITIONAL_INFO_EVENT_TYPE({})
  },

  methods: {
    ...mapActions('infoConstructor', [
      'loadSelectedAdditionalInfoCategory',
      'loadSelectedAdditionalInfoArticle',
      'loadSelectedAdditionalInfoEventType',
    ]),
    ...mapMutations('infoConstructor', ['SET_SELECTED_ADDITIONAL_INFO_ARTICLE', 'SET_SELECTED_ADDITIONAL_INFO_EVENT_TYPE']),

    async initialize() {
      if (this.currentCompanyId !== 'undefined') {
        await this.loadSelectedAdditionalInfoArticle(this.articleId)
        if (this.additionalInfoArticle.category) {
          await this.loadSelectedAdditionalInfoCategory(this.additionalInfoArticle.category.id)
        }
        if (this.additionalInfoArticle.event_code)
          await this.loadSelectedAdditionalInfoEventType(this.additionalInfoArticle.event_code.event_type.id)
        this.isLoaded = true
      } else {
        await this.displayWarningAlert({ message: 'Оберіть компанію' })
        await this.$router.replace('/spa')
      }
    },
  },
}
</script>

<style scoped></style>
